import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';

const PrivateRoute = () => {
  const { isLoggedIn } = useContext(AuthContext);

  if (!isLoggedIn) {
    // Redirect to the home page if not logged in
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
