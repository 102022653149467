import {useState, useEffect, useCallback} from 'react';
import AboutMeService from '../services/Portfolio/AboutMeService';

function usePortfolio(){
    const[portfolios, setPortfolios] = useState([]);
    const[portfolio, setPortfolio] = useState(null);
    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(null);

    

    const fetchPortfolios = useCallback(async()=>{
        setLoading(true);
        setError(null);
        try{
             const response = await AboutMeService.getAll();            
            console.log("Fetched portfolios:", response);  
            setPortfolios(response);
        } catch(err){
            setError(err);
        } finally{
            setLoading(false);
        }
    },[]);

    useEffect(()=>{
        fetchPortfolios();
    },[fetchPortfolios]);

    const fetchPortfolio = async(id)=>{
        try{
            const data = await AboutMeService.getById(id);
            setPortfolio(data);
        } catch(err){
            setError(err);
        }
    };

    const createPortfolio = async (newPortfolio)=>{
        try{
            await AboutMeService.create(newPortfolio);
            fetchPortfolios();
        } catch(err){
            setError(err);
        }
    };

    const updatePortfolio = async(id,updatedPortfolio)=>{
        try{
            await AboutMeService.update(id,updatedPortfolio);
            fetchPortfolios();
        }
        catch(err){
            setError(err);
        }
    };

    const deletePortfolio = async(id)=>{
        try{
            await AboutMeService.delete(id);
            fetchPortfolios();
        } catch(err){
            setError(err);
        }
    };

    const refetchPortfolios = fetchPortfolios;
    return{
        portfolios,
        portfolio,
        loading,
        error,
        fetchPortfolios,
        fetchPortfolio,
        createPortfolio,
        updatePortfolio,
        deletePortfolio,
        refetchPortfolios
    };
};

export default usePortfolio;