import React from 'react';

function Hetvik(){
    return(
        <div className='content'>
            <div className='container-fluid'>
                <h2>Hetvik Portfolio Management</h2>
                <div className='card'>
                    <div className='card-body'>
                        <p>Manage your portfolio details here.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hetvik;