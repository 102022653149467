import React,{useEffect, useState} from 'react';
import { useNavigate,  Routes, Route } from 'react-router-dom';
import '../../styles/dashboard.css';
import ExpenseManager from './ExpenseManager';
import Portfolio from './Portfolio/PortfolioManager';
import CodingGrill from './CodingGrillManager';
import Hetvik from './HetvikManager';
import Gopready from './GopreadyManager';

function Dashboard() {
    const[headerTitle, setHeaderTitle] = useState('Welcome to the Dashboard');
    const navigate = useNavigate();

    useEffect(()=> {
        const token = localStorage.getItem('accessToken');
        if(!token){
            navigate('/');
        }
    },[navigate]);

    return (
        <section className='dashboard'>
            <header className='dashboard-header'><h1>{headerTitle}</h1></header>            
            <div className="dashboard-content">
            <Routes>                
                <Route path="dashboard" element={<Dashboard setHeaderTitle={setHeaderTitle}/> }/>
                <Route path="portfolio" element={<Portfolio setHeaderTitle={setHeaderTitle}/>}/>                
                <Route path="expensemanager" element={<ExpenseManager setHeaderTitle={setHeaderTitle}/> }/>
                <Route path="codinggrill" element={<CodingGrill setHeaderTitle={setHeaderTitle}/>}/>
                <Route path="hetvik" element={<Hetvik setHeaderTitle={setHeaderTitle}/>}/>
                <Route path="gopready" element={<Gopready setHeaderTitle={setHeaderTitle}/>}/>
            </Routes>
            </div>            
        </section>
    );
}

export default Dashboard;
