import React from 'react';

function CodingGrill(){
    return(
        <div className='content'>
            <div className='container-fluid'>
                <h2>CodingGrill Management</h2>
                <div className='card'>
                    <div className='card-body'>
                        <p>Manage your Coding Grill Portfolio here.</p>
                    </div>
                </div>
            </div>            
        </div>
    );
}
export default CodingGrill;