import React, { useEffect, useState } from "react";
import usePortfolio from "../../../hooks/usePortfolio";
import { Table, Button, Container, Modal, Form, Alert, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function AboutMe() {
  const {
    portfolios,
    loading,
    error,
    createPortfolio,
    updatePortfolio,
    deletePortfolio,
    refetchPortfolios
  } = usePortfolio();
  const [showModal, setShowModal] = useState(false);
  const [editingPortfolio, setEditingPortfolio] = useState(null);
  const [formData, setFormData] = useState({
    Id: null,
    FirstName: "",
    LastName: "",
    DateOfBirth: "",
    WebsiteName: "",
    EmailId: "",
    PhoneNumber: "",
    Degree: "",
    Address: "",
    City: "",
    State: "",
  });

  useEffect(() => {
    console.log("Portfolios updated in AbouteMe:", portfolios);
  }, [portfolios]);

  if (loading) return <Spinner animation="border" role="Status"><span>Loading...</span></Spinner>
  if (error) {
    return (
        <Alert variant="danger">
            Error loading portfolios: {error.message}
            <Button variant="primary" onClick={refetchPortfolios} className="ms-3">
                Retry
            </Button>
        </Alert>
    );
  }

  if (!Array.isArray(portfolios) || portfolios.length === 0) {
    return <p>No portfolios available.</p>;
  }

  const handleModalClose = () => {
    setShowModal(false);
    setEditingPortfolio(null);
    setFormData({
      Id: null,
      FirstName: "",
      LastName: "",
      DateOfBirth: "",
      WebsiteName: "",
      EmailId: "",
      PhoneNumber: "",
      Degree: "",
      Address: "",
      City: "",
      State: "",
    });
  };

  const handleCreate = () => {
    setEditingPortfolio(null);
    setShowModal(true);
  };

  const handleEdit = (portfolio) => {
    setEditingPortfolio(portfolio);
    setFormData(portfolio);
    setShowModal(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure to delete this portfolio?")) {
      deletePortfolio(id);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (editingPortfolio) {
      updatePortfolio(formData);
    } else {
      createPortfolio(formData);
    }
    handleModalClose();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Container className="mt-4">
      <h1 className="text-center">About Me</h1>
      <button onClick={handleCreate}>Create New</button>
      <Table striped bordered hover responsive>
        <thead className="table-dark">
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Date Of Birth</th>
            <th>Website Name</th>
            <th>Email</th>
            <th>Phone No</th>
            <th>Degree</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Headline</th>
            <th>About Headline</th>
            <th>About Sub Headline</th>
            <th>Skill Headline</th>
            <th>Resume Headline</th>
            <th>Summary Headline</th>
            <th>Contact Headline</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {portfolios.map((item) => (
            <tr key={item.Id}>
              <td>{item.Id}</td>
              <td>{item.FirstName}</td>
              <td>{item.LastName}</td>
              <td>{new Date(item.DateOfBirth).toLocaleDateString()}</td>
              <td>{item.WebsiteName}</td>
              <td>{item.EmailId}</td>
              <td>{item.PhoneNumber}</td>
              <td>{item.Degree}</td>
              <td>{item.Address}</td>
              <td>{item.City}</td>
              <td>{item.State}</td>
              <td>{item.Headlines}</td>
              <td>{item.AboutHeadline}</td>
              <td>{item.AboutSubHeadline}</td>
              <td>{item.SkillHeadline}</td>
              <td>{item.ResumeHeadline}</td>
              <td>{item.SummaryHeadline}</td>
              <td>{item.ContactHeadline}</td>
              <td>
                <Button
                  variant="primary"
                  size="sm"
                  className="me-2"
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDelete(item.Id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Modal for create/Edit */}
      <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{editingPortfolio?"Edit Portfolio":"Create Portfolio"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleFormSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" name="FirstName" value={formData.FirstName} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" name="LastName" value={formData.LastName} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Date Of Birth</Form.Label>
                        <Form.Control type="date" name="DateOfBirth" value={formData.DateOfBirth} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text" name="WebsiteName" value={formData.WebsiteName} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email Id</Form.Label>
                        <Form.Control type="text" name="EmailId" value={formData.EmailId} onChange={handleInputChange} requird/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>PhoneNumber</Form.Label>
                        <Form.Control type="text" name="PhoneNumber" value={formData.PhoneNumber} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Degree</Form.Label>
                        <Form.Control type="text" name="Degree" value={formData.Degree} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" name="Address" value={formData.Address} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" name="City" value={formData.City} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" name="State" value={formData.State} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Headlines</Form.Label>
                        <Form.Control type="textarea" name="Headlines" value={formData.Headlines} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>About Headline</Form.Label>
                        <Form.Control type="textarea" name="AboutHeadline" value={formData.AboutHeadline} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>About Sub Headline</Form.Label>
                        <Form.Control type="textarea" name="AboutSubHeadline" value={formData.AboutSubHeadline} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Skill Headline</Form.Label>
                        <Form.Control type="textarea" name="SkillHeadline" value={formData.SkillHeadline} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Resume Headline</Form.Label>
                        <Form.Control type="textarea" name="ResumeHeadline" value={formData.ResumeHeadline} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Summary Headline</Form.Label>
                        <Form.Control type="textarea" name="SummaryHeadline" value={formData.SummaryHeadline} onChange={handleInputChange} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Contact Headline</Form.Label>
                        <Form.Control type="textArea" name="ContactHeadline" value={formData.ContactHeadline} onChange={handleInputChange} required/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">{editingPortfolio?"Update":"Create"}</Button>
            </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AboutMe;
