import React, { useState, useContext, useEffect } from 'react';
import NavMenu from './Navmenu';
import DashMenu from './Dashmenu';
import LoginModal from '../UI/LoginModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';

function Header() {
    const [modalShow, setModalShow] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {isLoggedIn, logout} = useContext(AuthContext);
    
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(()=>{
        if(!isLoggedIn){
            navigate('/');
        }
    },[isLoggedIn, navigate]);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    //const navigate = useNavigate(); // Replaced useHistory with useNavigate

    const handleLogout = () => { 
        logout()
        console.log('User logged out');
    };    

    const isOnDashboard = location.pathname.startsWith('/dashboard');

    return (
        <header id="header" className={`header dark-background d-flex flex-column ${isMenuOpen ? 'header-show' : ''}`}>
            <div className="header-toggle" onClick={toggleMenu}>
                <i className={`bi ${isMenuOpen ? 'bi-x' : 'bi-list'}`}></i>
            </div>
            <div className="profile-img">
                <img src="assets/img/my-profile-img.png" alt="" className="img-fluid rounded-circle" />
            </div>
            <Link to="#" className="logo d-flex align-items-center justify-content-center">
                <h1 className="sitename">Ravikant Hooda</h1>
            </Link>
            <div className="social-links text-center">
                <Link to="https://x.com/ravikant_hudda" className="twitter" target="_blank" rel="noreferrer">
                    <i className="bi bi-twitter-x"></i>
                </Link>
                <Link to="https://www.facebook.com/ravikant.hudda" className="facebook" target="_blank" rel="noreferrer">
                    <i className="bi bi-facebook"></i>
                </Link>
                <Link to="https://www.instagram.com/ravikanthooda/" className="instagram" target="_blank" rel="noreferrer">
                    <i className="bi bi-instagram"></i>
                </Link>
                <Link to="https://www.naukri.com/mnjuser/profile" className="google-plus" target="_blank" rel="noreferrer">
                    <i className="bi bi-briefcase"></i>
                </Link>
                <Link to="https://www.linkedin.com/in/ravikant-hudda/" className="linkedin" target="_blank" rel="noreferrer">
                    <i className="bi bi-linkedin"></i>
                </Link>   
                <Link onClick={() => isLoggedIn ? handleLogout() : setModalShow(true)}>
                    <i className={`bi ${isLoggedIn ? 'bi-door-open' : 'bi-door-closed'}`}></i>  
                </Link>       
            </div>
            {isOnDashboard?(
                <DashMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}/>
            ):(
                <NavMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />    
            )}            
            <LoginModal show={modalShow} onHide={() => setModalShow(false)}/>
        </header>      
    );
}

export default Header;
