import React, {useEffect, useState} from "react";

function SkillBar({skill, percentage}){
    const[width,setWidth]=useState(0);

    useEffect(()=>{
        setTimeout(() => {
            setWidth(percentage);
        }, 100);
    },[percentage]);

    return(
        <div className="progress">
            <span className="skill">
                <span>{skill}</span>
                <i className="val">{percentage}%</i>
            </span>
            <div className="progress-bar-wrap">
                <div className="progress-bar"
                role='progressbar'
                aria-valuenow={percentage}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{width: `${width}%`}}></div>
            </div>
        </div>
        
    );
}
export default SkillBar;