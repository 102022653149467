import React from 'react';
import {Link} from 'react-router-dom';
const fileUrl = 'http://ravikanthooda.com/assets/download/ravikanthudda_10Years_DotNet.pdf'; // URL to the file
const filename="ravikanthudda_10Years_DotNet.pdf"
function Resume(){
    return (
      <section id="resume" className="resume section">
        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <div className='row'>
            <h2 className='col-lg-11'>Resume</h2>
            <h2 className='col-lg-1'><Link 
        to={fileUrl} 
        download={filename}
        style={{ textDecoration: 'none' }}
      ><i className="bi bi-cloud-arrow-down text-right" style={{"cursor":"pointer"}}></i></Link></h2>
          </div>
          <p>
          Experienced Software Developer with a Decade of Expertise in Client/Server and Web-Based Solutions, Skilled in C#, ASP.NET, EF, SQL Server, ADO.NET, LINQ, MVC, and Web API. Adept in Analysis, Design, and Development within the Dot Net Ecosystem.
          </p>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <h3 className="resume-title">Summary</h3>

              <div className="resume-item pb-0">
                <h4>Ravikant Hudda</h4>
                <p>
                  <em>
                  My passion as a software engineer drives me to continuously explore and innovate in the tech world. Outside of work, I find joy in riding my bike, which offers a perfect balance of adventure and relaxation. My family is my greatest support and source of happiness, providing me with constant inspiration. Additionally, I have a keen interest in staying updated with the latest technological trends, fueling my curiosity and enthusiasm for the ever-evolving field of technology.
                  </em>
                </p>
                <ul>
                  <li>9071, Tower-J, 16th Avenue,<br/>Gaur City 2, Greater Noida West<br/>Uttar Pradesh, 201301</li>
                  <li>(91) 9990-965-066</li>
                  <li>ravikanthudda@gmail.com</li>
                </ul>
              </div>

              <h3 className="resume-title">Education</h3>
              <div className="resume-item">
                <h4>Master of Computer Applications</h4>
                <h5>2014 - 2017</h5>
                <p>
                  <em>Sikkim Manipal University</em>
                </p>
                <p>
                During this period, I completed my long-awaited Master's degree in Computer Applications while advancing my career as a Software Engineer. This achievement not only facilitated my career growth but also strengthened my proficiency in the technologies I work with.
                </p>
              </div>

              <div className="resume-item">
                <h4>Bachelor Of Arts</h4>
                <h5>2004 - 2009</h5>
                <p>
                  <em>Maharaja Ganga Singh University, Bikaner, Rajsthan</em>
                </p>
                <p>
                I earned my degree in Arts, fueled by a strong passion for English Literature and Economics. At the same time, I actively pursued and cultivated my career in IT, aligning my professional goals with my interests.
                </p>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
              <h3 className="resume-title">Professional Experience</h3>
              <div className="resume-item">
                <h4>Software Engineer II</h4>
                <h5>Jul, 2023 - Present</h5>
                <p>
                  <em>Daxko India PVT LTD, Noida</em>
                </p>
                <ul>
                  <li>
                    Lead in the design, development, and implementation of the
                    graphic, layout, and production communication materials
                  </li>
                  <li>
                    Delegate tasks to the 7 members of the design team and
                    provide counsel on all aspects of the project.{" "}
                  </li>
                  <li>
                    Supervise the assessment of all graphic materials in order
                    to ensure quality and accuracy of the design
                  </li>
                  <li>
                    Oversee the efficient use of production project budgets
                    ranging from $2,000 - $25,000
                  </li>
                </ul>
              </div>
              <div className="resume-item">
                <h4>Consultant</h4>
                <h5>Dec, 2019 - Jun, 2023</h5>
                <p>
                  <em>Globallogic, Noida</em>
                </p>
                <ul>
                  <li>
                    Developed numerous marketing programs (logos,
                    brochures,infographics, presentations, and advertisements).
                  </li>
                  <li>
                    Managed up to 5 projects or tasks at a given time while
                    under pressure
                  </li>
                  <li>
                    Recommended and consulted with clients on the most
                    appropriate graphic design
                  </li>
                  <li>
                    Created 4+ design presentations and proposals a month for
                    clients and account managers
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default Resume;