import React,{useEffect} from "react";
import AboutMe from './AboutMe';

function PortfolioManager({setHeaderTitle}){
    useEffect(()=>{
        setHeaderTitle("Portfolio Management");
    },[setHeaderTitle]);
    return(
        <div className="portfolio-manager">
            <div className="container-fluid">
                <section className="portfolio-body">
                <h2>Manage Your Portfolio Details Here</h2>
                {/*Content for managing portfolio items */}
                <div className="card mt-3">
                    <div className="card-body">
                        <p>Manage your portfolio details here.</p>
                        <AboutMe/>
                    </div>
                </div>
                </section>
                
            </div>
        </div>
    );
}
export default PortfolioManager;