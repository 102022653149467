const BASE_URL = "https://localhost:7218";

export const API_ENDPOINTS = {
  PORTFOLIO: {
    GET_ALL: `${BASE_URL}/api/AboutMe`,
    GET_BY_ID: (id) => `${BASE_URL}/api/AboutMe/${id}`,
    CREATE: `${BASE_URL}/api/AboutMe`,
    UPDATE: (id) => `${BASE_URL}/api/AboutMe/${id}`,
    DELETE: (id) => `${BASE_URL}/api/AboutMe/${id}`,
  },
};

export default API_ENDPOINTS;
