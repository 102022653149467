import React from "react";
import { Link } from 'react-router-dom';

function NavMenu({ isOpen, toggleMenu }) {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <nav id="navmenu" className={`navmenu ${isOpen ? 'open' : ''}`}>
      <ul>
        <li>
          <Link
            to="#hero"
            onClick={() => scrollToSection("hero")}
            className={window.location.hash === "#hero" ? "active" : ""}
          >
            <i className="bi bi-house navicon"></i>Home
          </Link>
        </li>
        <li>
          <Link
            to="#about"
            onClick={() => scrollToSection("about")}
            className={window.location.hash === "#about" ? "active" : ""}
          >
            <i className="bi bi-person navicon"></i>About
          </Link>
        </li>
        <li>
          <Link
            to="#resume"
            onClick={() => scrollToSection("resume")}
            className={window.location.hash === "#resume" ? "active" : ""}
          >
            <i className="bi bi-file-earmark-text navicon"></i>Resume
          </Link>
        </li>
        <li>
          <Link
            to="#contact"
            onClick={() => scrollToSection("contact")}
            className={window.location.hash === "#contact" ? "active" : ""}
          >
            <i className="bi bi-envelope navicon"></i>Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavMenu;
