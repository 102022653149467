import React from "react";

function Gopready(){
    return(
        <div className="content">
            <div className="container-fluid">
                <h2>Gopready Management</h2>
                <div className="card">
                    <div className="card-body">
                        <p>Manage your Gopready details here.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gopready;