import React from 'react';
import SkillBar from '../controls/skillBar';
function Skills(){   
    const skills = [
        { skill: 'CSS', percentage: 90 },
        { skill: 'JavaScript', percentage: 80 },
        { skill: 'React', percentage: 75 },
        { skill: 'HTML', percentage: 95 },
        { skill: 'Node.js', percentage: 70 },
        { skill: 'Python', percentage: 85 },
        // Add more skills here
      ];
      const midIndex = Math.ceil(skills.length/2);
      const firstHalf = skills.slice(0,midIndex);
      const secondHalf = skills.slice(midIndex);
return (
  <section id="skills" className="skills section light-background">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2>Skills</h2>
      <p>
      Throughout the course of my career, I've been fortunate to delve into and master a diverse array of technologies, each contributing to the rich tapestry of my professional expertise:
      </p>
    </div>

    <div className="container" data-aos="fade-up" data-aos-delay="100">
      <div className="row skills-content skills-animation">
        <div className="col-lg-6">
          {firstHalf.map((skillData, index) => (
            <SkillBar
              key={index}
              skill={skillData.skill}
              percentage={skillData.percentage}
            />
          ))}
        </div>
        <div className="col-lg-6">
          {secondHalf.map((skillData, index) => (
            <SkillBar
              key={index}
              skill={skillData.skill}
              percentage={skillData.percentage}
            />
          ))}
        </div>
      </div>
    </div>
  </section>
);
}
export default Skills;