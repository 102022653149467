import React, {createContext, useState, useEffect, useCallback, useRef} from 'react';

const AuthContext = createContext();

export const AuthProvider = ({children})=>{
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('accessToken'));
    const [accessToken, setAccessToken]=useState(localStorage.getItem('accessToken'));

    const TIMEOUT_DURATION = 10*60*1000;

    let logoutTimer=useRef(null);

    const login = (token)=>{
        localStorage.setItem('accessToken',token);
        setAccessToken(token);
        setIsLoggedIn(true);
        resetTimer();
    };

    const logout = ()=>{
        localStorage.removeItem('accessToken');
        setAccessToken(null);
        setIsLoggedIn(false);
        if(logoutTimer) clearTimeout(logoutTimer);
    };

    const resetTimer = useCallback(()=>{
        if(logoutTimer.current) clearTimeout(logoutTimer.current);
        logoutTimer.current = setTimeout(()=>{
            console.log('User inactive. Logging out.');
            logout();
        },TIMEOUT_DURATION);
    },[TIMEOUT_DURATION]);

    useEffect(()=>{
        if(isLoggedIn){
            resetTimer();

            window.addEventListener('mousemove', resetTimer);
            window.addEventListener('keydown', resetTimer);

            return() =>{
                if(logoutTimer.current) clearTimeout(logoutTimer.current);
                window.removeEventListener('mousemove', resetTimer);
                window.removeEventListener('keydown', resetTimer);
            };
        }
    }, [isLoggedIn, resetTimer]);

    useEffect(()=>{
        const token = localStorage.getItem('accessToken');
        if(token){
            setIsLoggedIn(true);
            setAccessToken(token);
        }
    }, []);

    return(
        <AuthContext.Provider value={{isLoggedIn, accessToken, login, logout}}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;