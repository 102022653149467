import React from "react";
import {Link, useLocation} from 'react-router-dom';

function DashMenu({isOpen, toggleMenu}){
    const location = useLocation();
    return(
        <div className="navmenu">
        <nav id="dashmenu" className={`dashmenu ${isOpen?'open':''}`}>
            <ul>
                <li><Link to="dashboard" className={location.pathname==="/dashboard" ? "active" : ""}><i className="bi bi-speedometer2 navicon" onClick={toggleMenu}></i>Dashboard</Link></li>                
                <li><Link to="dashboard/portfolio" className={location.pathname.includes("portfolio") ? "active" : ""}><i className="bi bi-file-earmark-person navicon" onClick={toggleMenu}></i>Portfolio Manager</Link></li>
                <li><Link to="dashboard/expensemanager" className={location.pathname.includes("expensemanager") ? "active" : ""} onClick={toggleMenu}><i className="bi bi-wallet navicon"></i>Expense Manager</Link></li>                
                <li><Link to="dashboard/codinggrill" className={location.pathname.includes("codinggrill") ? "active" : ""}><i className="bi bi-terminal navicon" onClick={toggleMenu}></i>CodingGrill Manager </Link></li>
                 <li><Link to="dashboard/hetvik" className={location.pathname.includes("hetvik") ? "active" : ""}><i className="bi bi-file-earmark-person navicon" onClick={toggleMenu}></i>Hetvik's Portfolio</Link></li>        
                <li><Link to="dashboard/gopready" className={location.pathname.includes("gopready") ? "active" : ""}><i className="bi bi-book navicon" onClick={toggleMenu}></i>Gopready</Link></li>                
            </ul>
        </nav>
        
        </div>
    );
}
export default DashMenu;