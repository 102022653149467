import React from 'react';

function ExpenseManager()
{
    return(
        <div className='content'>
            <div className='container-fluid'>
                <h2>Expense Manager</h2>
                <div className='card'>
                    <div className='card-body'>
                        <p>Manage your Expense here.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ExpenseManager;