import PortfolioRepository from "../../repositories/portfolioRepository";

const AboutMeService = {
  getAll: async () => {
    try {
        const response =  await PortfolioRepository.getAll();
        console.log("Fetched portfolios:", response.data);  
        return response;
    } catch (error) {
      console.error("Failed to fetch all AboutMe", error);
      throw error;
    }
  },
  getById: async (id) => {    
    try {
        const response = await PortfolioRepository.getById(id);
        return response;
    } catch (error) {
      console.error(`Failed to fetch AboutMe ${id}`, error);
      throw error;
    }
  },
  create: async (newAboutMe) => {
    try {
        const response = await PortfolioRepository.create(newAboutMe);
        return response.data;
    } catch (error) {
      console.error("Failed to create AboutMe", error);
      throw error;
    }
  },
  update: async (id, updatedAboutMe) => {
    try {
        const response = await PortfolioRepository.update(id, updatedAboutMe);
        return response.data;
    } catch (error) {
      console.error(`Failed to update AboutMe for ${id}`, error);
      throw error;
    }
  },
  delete: async (id) => {
    try {
        await PortfolioRepository.delete(id);
    } catch (error) {
      console.error(`Failed to delete AboutMe for ${id}`, error);
      throw error;
    }
  },
};

export default AboutMeService;
