import axiosInstance from "../utils/axiosInstance";
import API_ENDPOINTS from "../config/apiEndpoints";

class GenericRepository {
  constructor(entity) {
    this.entity = entity;
    console.log("Initializing GenericRepository for entity: ", this.entity);
  }

  getToken = () => localStorage.getItem('accessToken');

  getAll = async () => {
    try {
      const token = this.getToken();
      console.log("Access Token in Generic Repository is: ", token);
      const url = API_ENDPOINTS[this.entity]?.GET_ALL;
      const fullUrl = `${axiosInstance.defaults.baseURL}${url}`;
      console.log("API URL for GET_ALL: ", fullUrl);

      if (!url) {
        throw new Error(
          `API endpoint for ${this.entity} GET_ALL is not defined.`
        );
      }

      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching all ${this.entity}`, error);
      throw error;
    }
  };

  getById = async (id) => {
    try {
      const token = this.getToken();
      const url = API_ENDPOINTS[this.entity].GET_BY_ID(id);
      const fullUrl = `${axiosInstance.defaults.baseURL}${url}`;
      console.log("API URL for GET_BY_ID: ", fullUrl);

      return await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(`Error fetching id ${id} for ${this.entity}`, error);
      throw error;
    }
  };

  // Similarly update other methods to fetch token dynamically

  create = async (data) => {
    try {
      const token = this.getToken();
      const url = API_ENDPOINTS[this.entity].CREATE;
      const fullUrl = `${axiosInstance.defaults.baseURL}${url}`;
      console.log("API URL for CREATE: ", fullUrl);

      return await axiosInstance.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(`Error creating ${this.entity} for ${data}`, error);
      throw error;
    }
  };

  update = async (id, data) => {
    try {
      const token = this.getToken();
      const url = API_ENDPOINTS[this.entity].UPDATE(id);
      const fullUrl = `${axiosInstance.defaults.baseURL}${url}`;
      console.log("API URL for UPDATE: ", fullUrl);

      return await axiosInstance.put(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(`Error updating id ${id} for ${this.entity}`, error);
      throw error;
    }
  };

  delete = async (id) => {
    try {
      const token = this.getToken();
      const url = API_ENDPOINTS[this.entity].DELETE(id);
      const fullUrl = `${axiosInstance.defaults.baseURL}${url}`;
      console.log("API URL for DELETE: ", fullUrl);

      return await axiosInstance.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(`Error deleting ${id} for ${this.entity}`, error);
      throw error;
    }
  };
}

export default GenericRepository;
