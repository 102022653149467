import React from 'react';
import {Link} from 'react-router-dom';

function NotFound(){
    return(
        <div style={{textAlign: 'center', padding: '50px'}}>
            <h1>404 - Page Not Found</h1>
            <p>The Page you are looking for does not exist.</p>
            <Link to="/">Go to Homepage</Link>
        </div>
    );
}
export default NotFound;